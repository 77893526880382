<template>
  <div class="flex flex-wrap w-full pb-8 sm:pb-8 gap-[1.875rem] sm:gap-0">
    <div v-for="partner in partners" class="flex w-[9.375rem] h-[7.5rem] flex-col
    justify-center items-center sm:items-start font-semibold text-[1rem] sm:w-[50%]">
      <div class="sm:p-4 w-full flex-col flex items-start justify-start box-border text-left sm:text-[14px] cursor-pointer" @click="clickPartner(partner)">{{ partner.type.name }}
        <el-image :src="`${url}/assets/${partner?.images[0]?.directus_files_id}?key=webp`"
                  :alt="`${partner.name}`" class="object-cover w-full h-[5.0625rem]" fit="contain"/>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
  import { useGlobalState } from '../../../utils/store'
  import {ref} from "vue";

  const props = defineProps<{
    event: any,
    types: any,
    index: number,
  }>()

  const partners = ref([]);

  for (const type of props.types) {
    const query = new URLSearchParams({
      "filter[type]" : type.id,
      "filter[event]" : props.event.id,
      "fields" : "type.name, *, images.directus_files_id",
    });

    const data = await fetch(`${import.meta.env.PUBLIC_API_URL}/items/EventPartner?${query.toString()}`)
      .then((response) =>
        response.json()
      );

    partners.value.push(data.data);
  }

  partners.value = partners.value.flat();
  partners.value = partners.value.sort((a, b) => a.priority - b.priority);

  const url = import.meta.env.PUBLIC_API_URL;

  const state = useGlobalState();

  const clickPartner = (partner: any) => {
    state.isOpen.value = true;
    state.name.value = partner.name;
    state.description.value = partner.description;
    state.images.value = partner.images;
    state.instagram.value = partner.instagram;
    state.website.value = partner.website;
    state.type.value = "tenant";
    window.dialog.showModal();
  }
</script>
